import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../../components/theme/layout"
import SEO from "../../components/theme/header/seo"
import LinkWrapper from "../../components/reusable-components/link-wrapper"
import FlexSection from "../../components/reusable-components/layout/flex-section"

import PhoneSvg from "../../images/icons/phone"
import MailSvg from "../../images/icons/mail"
import LocationSvg from "../../images/icons/icon-feather-map-pin"

import { getProperty, replaceAll } from "../../lib/utils"

const BranchPage = ({ pageContext, data, location }) => {
  const name = getProperty(data, ["wpPage", "title"], "")
  const acf = getProperty(data, ["wpPage", "acf_branch_pages"], "")
  const phone = getProperty(acf, ["branchTelephone"], "")
  const mail = getProperty(acf, ["branchEmail"], "")
  const address = getProperty(acf, ["branchAddress"], "")
  const addressLink = getProperty(acf, ["branchAddressLink"], "")
  const branchMapLink = getProperty(acf, ["branchMapLink"], "")
  const yoast = getProperty(data, ["seaPage", "seo"], {})

  const linkMatch =
    branchMapLink &&
    branchMapLink.match(/\s*<iframe.*src=("[^"]+"|'[^']+').*<\/iframe>\s*/)

  const branchMapLinkParsed =
    Array.isArray(linkMatch) &&
    linkMatch.length > 1 &&
    linkMatch[1].slice(1, -1)

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO
        title={getProperty(data, ["seaPage", "seo", "title"], "")}
        location={location}
        yoast={yoast}
      />
      <h1 dangerouslySetInnerHTML={{ __html: name }} />
      <FlexSection
        direction={"row"}
        justify={"space-between"}
        margin={"6rem auto 6rem"}
      >
        <BranchInfoSectionStyling>
          <h2>Contact details</h2>
          {!phone ? null : (
            <BranchInfoRowStyling>
              <PhoneSvg />
              <a href={`tel:${replaceAll(phone.split("/")[0], " ", "")}`}>
                {phone}
              </a>
            </BranchInfoRowStyling>
          )}
          {!mail ? null : (
            <BranchInfoRowStyling>
              <MailSvg />
              <a href={`mailto:${mail}`}>{mail}</a>
            </BranchInfoRowStyling>
          )}
          {!address ? null : (
            <BranchInfoRowStyling>
              <LocationSvg />
              <a href={addressLink || "#"} target="_blank" rel="noreferrer">
                {address}
              </a>
            </BranchInfoRowStyling>
          )}
          <h5>Got a question?</h5>
          <LinkWrapper className="button-class" to="/contact-us/">
            Send us a message
          </LinkWrapper>
        </BranchInfoSectionStyling>
        {branchMapLinkParsed && (
          <StyledIframe
            src={branchMapLinkParsed}
            width="600"
            height="560"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
          />
        )}
      </FlexSection>
    </Layout>
  )
}

export default BranchPage

// ===============
//     STYLES
// ===============
const BranchInfoRowStyling = styled.div`
  margin-bottom: 2rem;
  svg {
    width: 32px;
    height: 32px;
    stroke: ${({ theme }) => theme.colors.blue};
    vertical-align: top;
    margin-top: 1rem;
  }
  a {
    padding-left: 40px;
    display: inline-block;
    width: calc(100% - 32px);
    font-size: 1.777rem;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    a {
      font-size: 1em;
      width: calc(100% - 16px);
      padding-left: 20px;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-top: 0.5rem;
    }
  }
`
const BranchInfoSectionStyling = styled.div`
  h2 {
    margin-bottom: 3rem;
  }
  h5 {
    margin: 4rem 0 2rem;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    h2 {
      margin-bottom: 3rem;
    }
    branchMapLink h5 {
      text-align: center;
    }
    h5 ~ a {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
`
const StyledIframe = styled.iframe`
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin: 6rem auto 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    height: 400px;
    margin: 6rem auto 0;
  }
`

// ===============
//      QUERY
// ===============
export const branchQueryData = graphql`
  query GET_BRANCH_PAGE_QUERY($slug: String) {
    wpPage(slug: { eq: $slug }) {
      title
      acf_branch_pages {
        branchTelephone
        branchEmail
        branchAddress
        branchAddressLink
        branchMapLink
      }
    }
    seaPage(slug: { eq: $slug }) {
      seo {
        og_description
        meta_description
        description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
