import React from "react"

const IconFeatherMapPin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.956"
    height="37.39"
    viewBox="0 0 30.956 37.39"
  >
    <g
      id="Icon_feather-map-pin"
      data-name="Icon feather-map-pin"
      transform="translate(1 1)"
    >
      <path
        id="Path_78"
        data-name="Path 78"
        d="M33.456,15.978c0,11.261-14.478,20.912-14.478,20.912S4.5,27.238,4.5,15.978a14.478,14.478,0,0,1,28.956,0Z"
        transform="translate(-4.5 -1.5)"
        fill="none"
        stroke="#003a8d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_79"
        data-name="Path 79"
        d="M23.152,15.326A4.826,4.826,0,1,1,18.326,10.5,4.826,4.826,0,0,1,23.152,15.326Z"
        transform="translate(-3.848 -0.848)"
        fill="none"
        stroke="#003a8d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default IconFeatherMapPin
